<template>
  <div id="home" style="min-height: 100vh">
    <div class="home">
      <van-pull-refresh v-model="loading" @refresh="refreshTours">
        <van-row>
          <van-col span="20">
            <van-row>
              <van-col span="5">
                <div class="home-header">游客</div>
              </van-col>
              <van-col span="19">
                <svg-icon
                  :data_iconName="'cloudy-day'"
                  :className="'signout_icon'"
                  style="line-height: 25px"
                  @click.native="$router.push('/weather')"
                />
              </van-col>
            </van-row>
          </van-col>
          <van-col span="4" style="text-align: right" @click="sign_out">
            <svg-icon
              :data_iconName="'signout'"
              :className="'signout_icon'"
              style="vertical-align: 0.1rem"
            />
          </van-col>
        </van-row>
        <div class="home-body">
          <card>
            <template #content>
              <van-row align="center">
                <van-col span="5" @click="onWodeClick">
                  <van-image
                    style="margin-top: 10px"
                    round
                    :src="user.user ? user.user.avatar : ''"
                    fit="cover"
                    width="60"
                    height="60"
                  />
                </van-col>
                <van-col span="15" style="font-size: 12px" @click="onWodeClick">
                  <van-row
                    style="font-size: 18px; font-weight: 600; margin-top: 10px"
                  >
                    <van-col span="10">
                      {{ user.user ? user.user.username : "" }}
                    </van-col>
                  </van-row>
                  <van-row style="margin-top: 5px; color: grey">
                    电话：{{ user.user ? user.user.mobile : "" }}
                  </van-row>
                </van-col>
                <van-col
                  span="4"
                  @click="toWodeEdit()"
                  style="margin-top: 0.4rem"
                >
                  <svg-icon
                    :data_iconName="'edit-deep'"
                    :className="'setting_icon'"
                  />
                </van-col>
              </van-row>
            </template>
          </card>
          <div class="home-body-footer">
            <van-row gutter="20">
              <van-col
                span="12"
                v-for="(item, index) in menus"
                :key="index"
                class="home-body-footer-item"
                @click="onMenuClick(item)"
              >
                <van-row style="line-height: 5px; text-align: right">
                  <van-badge
                    dot
                    style="margin-right: 0.4rem"
                    v-if="item.badge"
                  />
                  <van-badge
                    dot
                    color="transparent"
                    style="margin-right: 0.4rem"
                    v-if="!item.badge"
                  />
                </van-row>
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-col>
              <van-col
                span="12"
                @click.native="showBaojingPanel = true"
                class="home-body-footer-item"
              >
                <van-row style="line-height: 5px; text-align: right">
                  <van-badge
                    dot
                    color="transparent"
                    style="margin-right: 0.4rem"
                  />
                </van-row>
                <svg-icon data_iconName="baojing" :className="'item_icon'" />
                <div class="item-text">一键报警</div>
              </van-col>
            </van-row>
          </div>
          <tourCard
            :tour="tour"
            v-for="(tour, idx) in onGoingTours"
            :key="idx"
          ></tourCard>
        </div>
      </van-pull-refresh>
    </div>
    <van-action-sheet v-model="showBaojingPanel">
      <div class="content">
        <baojing
          v-on:cancel-baojing="showBaojingPanel = false"
          :poi="poi"
        ></baojing>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import tourCard from "@/views/components/TourCard";
import card from "@/views/components/Card";
import baojing from "@/views/baojing/index";
import { mapGetters } from "vuex";
import { Dialog } from "vant";
import api from "@/services/apis";
import amapApi from "@/services/amap.service";
export default {
  name: "Home",
  components: {
    card,
    baojing,
    tourCard,
  },
  data() {
    return {
      loading: false,
      menus: [
        {
          text: "扫一扫",
          icon: "scan",
          path: "/scan",
          badge: false,
        },
        {
          text: "我的行程",
          icon: "trip",
          path: "/tour",
          badge: false,
        },
        {
          text: "通知",
          icon: "pufa",
          path: "/notification",
          badge: false,
        },
        {
          text: "安全防护",
          icon: "xiaoxi",
          path: "/anquan",
          badge: false,
        },
        {
          text: "信息查询",
          icon: "tuan-default",
          path: "/xinxi",
          badge: false,
        },
        // {
        //   text: "疫情",
        //   icon: "event",
        //   path: "/yiqing",
        //   badge: false,
        // },
        {
          text: "通讯录",
          icon: "tuan-default",
          path: "/tongxun",
          badge: false,
        },
      ],
      showBaojingPanel: false,
      locationStr: "",
      poi: {},
    };
  },
  watch: {
    showBaojingPanel: {
      handler(val) {
        const _self = this;
        if (val) {
          navigator.geolocation.getCurrentPosition((position) => {
            _self.locationStr = `${position.coords.longitude},${position.coords.latitude}`;

            const query = {
              keywords: "派出所",
              key: amapApi.webServiceKey,
              location: _self.locationStr,
            };
            amapApi.search_around(query).then((res) => {
              if (res.status === 200 && res.data) {
                for (let i = 0; i < res.data.pois.length; i++) {
                  const item = res.data.pois[i];
                  if (item.tel.length != 0) {
                    _self.poi = item;
                    break;
                  }
                }
              }
            });
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters("travel_list", ["onGoingTours"]),
    ...mapGetters("wode", {
      user: "getPersonInfo",
    }),
  },
  created() {
    const _self = this;
    this.$store.dispatch("wode/getPersonInfo");
    this.$store.dispatch("travel_list/getAllTravelLists", 1);
    api.visitors_count_info().then((res) => {
      if (res.status === 200) {
        _self.menus[2].badge = res.data.notification_unread_count > 0;
        _self.menus[3].badge = res.data.tourist_yu_an_unread_count > 0;
      }
    });
  },
  methods: {
    onMenuClick(item) {
      this.$router.push({ path: item.path });
    },
    onTourClick(id) {
      this.$router.push(`/tour/info/${id}`);
    },
    toWodeEdit() {
      this.$router.push("/wode/edit");
    },
    onWodeClick() {
      this.$router.push("/wode/main");
    },
    sign_out() {
      const _self = this;
      Dialog.confirm({
        title: "操作提醒",
        message: "退出当前账号？",
      }).then(() => {
        _self.$store.dispatch("wode/resetState");
        _self.$store.dispatch("travel_list/resetState");
        _self.$router.push("/login");
      });
    },
    refreshTours() {
      const _self = this;
      this.$store.dispatch("travel_list/getAllTravelLists", 1).then((res) => {
        if (res.status === 200) {
          _self.loading = false;
        }
      });
    },
  },
};
</script>

<style>
.home {
  padding: 0.3rem 0.2rem 1rem 0.2rem;
  height: 100vh;
  overflow-y: scroll;
}
.home-header {
  color: #fff;
  font-weight: bold;
  font-size: 0.5rem;
  height: 10vh;
  width: 100%;
}
.home-body {
  width: 100%;
  padding-bottom: 2rem;
}
.home-body-header {
  height: 10vh;
  position: relative;
}
.home-body-header .avatar {
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.home-body-header .avatar img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.home-body-header .username {
  position: absolute;
  top: 0;
  left: 1rem;
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  position: absolute;
  top: 0.35rem;
  left: 1rem;
  color: #fff;
  font-size: 0.25rem;
  /* font-weight: bold; */
}
.home-body-middle {
  background: #fff;
  height: 15vh;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  border-radius: 28px;
  padding: 0.5rem;
}
.home-body-middle .number {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.home-body-middle .text {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.home-body-footer {
  background: #fff;
  /* height: 35vh; */
  margin-top: 10px;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 20px;
  border-radius: 20px;
  padding-bottom: 15px;
  /* padding: .4rem; */
}
.home-body-footer-item {
  text-align: center;
  padding: 0.3rem;
}
.item-text {
  font-size: 16px;
  color: #666666;
}
.item_icon {
  font-size: 1.4rem;
}
.sitting {
  position: absolute;
  top: -0.2rem;
  right: 0px;
}
.setting_icon {
  font-size: 0.6rem;
}
.signout {
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
}
.row {
  font-size: 14px;
  color: grey;
}
.content {
  padding: 16px 16px 160px;
}
.signout_icon {
  font-size: 0.8rem;
}
.setting_icon {
  font-size: 0.8rem;
}
.home-header {
  text-shadow: 0 0 2px #000;
}
</style>
